import React from "react";
import styled from "styled-components";

import logo from './logo.svg';

const AgimaLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 36px;
  bottom: 34px;
  z-index: 1000000;
  @media (max-width: 991px) {
    position: relative;
    right: auto;
    bottom: auto;
    margin: 25px auto 0 auto;
  }
`;

const AgimaLogo = styled.img`
  width: 121px;
  height: 55px;
  object-fit: contain;
  object-position: center;
`;

const Agima = () => {
  return(
    <AgimaLink href="https://agima.partners/?utm_source=together-with-agima&amp;utm_medium=beydzh&amp;utm_campaign=technaxis" target='_blank'>
      <AgimaLogo src={logo} alt='Agima' />
    </AgimaLink>
  )
};

export default Agima;